@import './colors';

$omnes-font-family: Omnes, Helvetica, Arial, sans-serif;

%headline {
  font-size: 35px;
  font-weight: 600;
  line-height: 118.5%;
}

%secondaryHeadline {
  font-size: 22px;
  font-weight: 600;
  line-height: 150%;
}

%smallHeadline {
  font-size: 18px;
  font-weight: 600;
  line-height: 133.1%;
}

%bodyLarge {
  font-size: 18px;
  font-weight: 500;
  line-height: 154.4%;
}

%linkText {
  color: $vroom-link-teal;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    color: $vroom-dark-teal;
    font-weight: 600;
  }
}
