@import './fonts';
@import './typography';

html,
body {
  font-family: $omnes-font-family;
  font-size: 15px;
  font-weight: 500;
  line-height: 158.4%;
  margin: 0;
  padding: 0;
}

* {
  box-sizing: border-box;
}

a {
  color: $vroom-link-teal;
}
