/* Primary colors */
$vroom-dark-orange: #e99200;
$vroom-link-teal: #00a6a2;
$vroom-dark-teal: #00878c;

/* Secondary colors */
$secondary-blue: #2d69eb;
$secondary-light-blue: #e6f8f8;
$secondary-purple: #b95096;
$secondary-dark-purple: #91379d;
$secondary-crimson: #d63156;
$secondary-green: #219653;
$secondary-red: #f54641;

$white: #fff;
$black: #000;
$gray: #999;
$light-gray: #f2f2f2;
$dark-gray: #545454;
$background-beige: #fffbf5;
$background-gray: #fbfbfb;
$background-lavender: #f4f7fe;
$background-light-blue: #f5fbfb;
$border-gray: #dbdbdb;
$link-gray: #949494;
$field-border-gray: #dcdcdc;
$placeholder-gray: #b0b0b0;
$text-gray: #828282;
$error-red: #eb5757;
$text-brown: #47341f;
