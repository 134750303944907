/* This stylesheet generated by Transfonter (https://transfonter.org) on September 21, 2017 7:41 PM */

@font-face {
  font-family: 'Omnes';
  font-style: italic;
  font-weight: 500;
  src: url('/fonts/omnes/Omnes-MediumItalic.eot');
  src: url('/fonts/omnes/Omnes-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/omnes/Omnes-MediumItalic.woff') format('woff'),
    url('/fonts/omnes/Omnes-MediumItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Omnes';
  font-style: normal;
  font-weight: 500;
  src: url('/fonts/omnes/Omnes-Medium.eot');
  src: url('/fonts/omnes/Omnes-Medium.eot?#iefix') format('embedded-opentype'),
    url('/fonts/omnes/Omnes-Medium.woff') format('woff'),
    url('/fonts/omnes/Omnes-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Omnes';
  font-style: italic;
  font-weight: 600;
  src: url('/fonts/omnes/Omnes-SemiboldItalic.eot');
  src: url('/fonts/omnes/Omnes-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/omnes/Omnes-SemiboldItalic.woff') format('woff'),
    url('/fonts/omnes/Omnes-SemiboldItalic.ttf') format('truetype');
}

@font-face {
  font-family: 'Omnes';
  font-style: normal;
  font-weight: 600;
  src: url('/fonts/omnes/Omnes-Semibold.eot');
  src: url('/fonts/omnes/Omnes-Semibold.eot?#iefix') format('embedded-opentype'),
    url('/fonts/omnes/Omnes-Semibold.woff') format('woff'),
    url('/fonts/omnes/Omnes-Semibold.ttf') format('truetype');
}

@font-face {
  font-family: 'Omnes';
  font-style: normal;
  font-weight: normal;
  src: url('/fonts/omnes/Omnes-Regular.eot');
  src: url('/fonts/omnes/Omnes-Regular.eot?#iefix') format('embedded-opentype'),
    url('/fonts/omnes/Omnes-Regular.woff') format('woff'),
    url('/fonts/omnes/Omnes-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Omnes';
  font-style: italic;
  font-weight: normal;
  src: url('/fonts/omnes/Omnes-RegularItalic.eot');
  src: url('/fonts/omnes/Omnes-RegularItalic.eot?#iefix') format('embedded-opentype'),
    url('/fonts/omnes/Omnes-RegularItalic.woff') format('woff'),
    url('/fonts/omnes/Omnes-RegularItalic.ttf') format('truetype');
}
